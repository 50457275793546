@import '../../_variables.scss';

.footer {
    height: auto;
    padding-top: 100px;
    padding-bottom: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: $footer-color;

    .footer__content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 60px;

        .footer__content_wd {
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-direction: row;
            width: 80%;

            .footer__left {
                width: 50%;
                display: flex;
                flex-direction: column;
                gap: 30px;

                .item {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    height: auto;
                    gap: 20px;
    
                    img {
                        width: 40px;   /* Largura consistente */
                    }
                    p {
                        color: $text-color-light;
                        font-size: 1.2rem;
                        margin: 0;
                    }
                }
            }

            .footer__right {
                display: flex;
                flex-direction: column;
                gap: 40px;

                .footer__right__social {
                    display: flex;
                    justify-content: center;
                    gap: 20px;
                }
                .logoFooter {
                    width: 200px;
                }
                .icon-socialmedia {
                    transition: 0.3s ease-in-out;
                }
                .icon-socialmedia:hover {
                    cursor: pointer;
                    opacity: 70%;
                }
            }
        }

        .seloMicrosoft {
            width: 15%;
        }
    }

    // Media Queries para dispositivos móveis
    @media (max-width: 768px) {
        .footer__content {
            gap: 40px;

            .footer__content_wd {
                flex-direction: column;
                align-items: center;
                width: 90%;
                gap: 30px;

                .footer__left {
                    width: 100%;
                    align-items: center;
                    text-align: center;

                    .item {
                        justify-content: center;
                        gap: 15px;

                        img {
                            width: 30px;
                            height: 30px;
                        }

                        p {
                            font-size: 1rem;
                        }
                    }
                }

                .footer__right {
                    width: 100%;
                    align-items: center;

                    img {
                        width: 80%;
                    }

                    .logoFooter {
                        width: 60%;
                    }

                    .footer__right__social {
                        gap: 15px;
                    }
                }
            }

            .seloMicrosoft {
                width: 20%;
            }
        }
    }

    @media (max-width: 480px) {
        .footer__content {
            gap: 20px;

            .footer__content_wd {
                gap: 20px;
                margin-bottom: 30px;

                .footer__left {
                    gap: 10px;
                    margin-bottom: 30px;
                    .item {
                        gap: 10px;

                        img {
                            width: 25px;
                            height: 25px;
                        }

                        p {
                            font-size: 0.9rem;
                        }
                    }
                }

                .footer__right {
                    gap: 20px;

                    img {
                        width: 70%;
                    }

                    .footer__right__social {
                        gap: 10px;
                    }
                }
            }

            .seloMicrosoft {
                width: 50%;
            }
        }
    }

    // Media Queries para monitores grandes
    @media (min-width: 1650px) {
        .footer__content {
            gap: 80px;

            .footer__content_wd {
                gap: 80px;

                .footer__left {
                    width: 60%;
                    gap: 50px;

                    .item {
                        gap: 40px;

                        img {
                            width: 50px;
                            height: 50px;
                        }

                        p {
                            font-size: 1.7rem;
                        }
                    }
                }

                .footer__right {
                    gap: 60px;

                    .footer__right__social {
                        gap: 30px;
                    }
                }
            }

            .seloMicrosoft {
                width: 10%;
            }
        }
    }
}

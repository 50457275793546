@import '../../_variables.scss';

.systems-section {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h2 {
        width: 65%;
        margin: 0;
        margin-top: 60px;
        font-size: 2rem;
        text-align: center;
        color: $text-color-dark;
        align-self: center;
    }
      
    .systems-section__content {
        display: flex;
        justify-content: center;

        .systems-grid {
            display: flex;
            flex-wrap: wrap;
            margin-top: 50px;
            margin-bottom: 100px;
            gap: 40px;
            justify-content: center;
            width: 70%;

            .system-image {
                max-width: 150px; /* Ajuste conforme necessário */
                max-height: 60px;
                flex: 1 1 100px; /* Permite que as imagens cresçam e encolham */
                object-fit: contain; /* Mantém a proporção da imagem */
                margin-bottom: 30px;
            }
        }
               
    }     
}

@media (max-width: 480px) {
    .systems-section {
        height: auto;
        padding: 20px;
        h2 {
            width: 90%;
            height: auto;
            overflow-y: hidden;
        }
    }
}
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

.App {
    width: 100vw;
    height: auto;
    font-family: 'Quicksand', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;

    ::selection {
        background-color: #535353; /* Cor do fundo da seleção */
        color: #fff; /* Cor do texto da seleção */
    }
  
  /* Para suportar navegadores mais antigos que não usam ::selection */
    ::-moz-selection {
        background-color: #535353; /* Cor do fundo da seleção para Firefox */
        color: #fff; /* Cor do texto da seleção para Firefox */
    }
}

* {
    overflow-x: hidden;
}

/* Personalizar a barra de rolagem */
::-webkit-scrollbar {
    width: 7px; /* Largura da barra de rolagem */
}

/* Personalizar o track (área onde a barra de rolagem se move) */
::-webkit-scrollbar-track {
    background: #a1a1a1; /* Cor do track */
}

/* Personalizar o polegar (a parte que você arrasta) */
::-webkit-scrollbar-thumb {
    background: #02007a; /* Cor do polegar */
    border-radius: 10px; /* Arredondar as bordas do polegar */
}

/* Personalizar o polegar quando está sendo rolado */
::-webkit-scrollbar-thumb:hover {
    background: #4d39bb; /* Cor do polegar quando o mouse está sobre ele */
}
@import '../../variables.scss';

.initial-section {
  height: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 50px;
  overflow: hidden;

  .initial-section__background {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }
  
  .initial-section__logo {
    z-index: 100;
    width: 15%;
    position: absolute;
    top: 20px;
    left: 25px;
  }
  
  .initial-section__mockup-notebook {
    z-index: 100;
    width: 50%;
  }
  
  .initial-section__content {
    height: 90%;
    width: calc(50% - 50px);
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    left: 50px;
    gap: 20px;
    overflow-y: hidden;
    
    h1 {
      color: $text-color-light;
      font-size: 2.3rem;
      margin: 0;
      overflow-y: hidden;
    }
    
    p {
      color: $text-color-light;
      font-size: 1.3rem;
      width: 80%;
      margin: 0;
      overflow-y: hidden;
    }
    
    button {
      background-color: $button-color;
      color: $text-color-light;
      border: none;
      width: 90%;
      padding: 10px 0px;
      border-radius: 10px;
      cursor: pointer;
      font-size: 1.2rem;
      transition: background-color 0.3s;
      
      &:hover {
        background-color: darken($button-color, 10%);
      }
      
      font-family: 'Quicksand', sans-serif;
      overflow-y: hidden;
    }
  }

  .fade-in-section {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
    overflow-y: hidden;
    
    &.visible {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media (max-width: 960px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;

    .initial-section__logo {
      width: 50%;
      top: 10px;
      left: 10px;
    }

    .initial-section__mockup-notebook {
      display: none;

    }

    .initial-section__content {
      width: 90% !important;
      height: 100%;
      padding: 20px;
      text-align: center;
      align-items: center;
      justify-content: center;
      
      h1 {
        font-size: 1.8rem;
      }
      
      p {
        font-size: 1.1rem;
        width: 100%;
      }
      
      button {
        font-size: 1rem;
        width: 100%;
      }
    }
  }

  @media (max-width: 480px) {

    .initial-section__mockup-notebook {
      width: 90%;
    }

    .initial-section__content {
      h1 {
        font-size: 1.5rem;
      }
      
      p {
        font-size: 1rem;
      }
      
      button {
        font-size: 0.9rem;
      }
    }
  }

  @media (min-width: 1650px) {
    .initial-section__logo {
      width: 10%;
      top: 30px;
      left: 30px;
    }
  
    .initial-section__mockup-notebook {
      width: 45%;
    }
  
    .initial-section__content {
      width: calc(50% - 75px);
      h1 {
        font-size: 3rem;
      }
      
      p {
        font-size: 1.6rem;
      }
      
      button {
        font-size: 1.9rem;
      }
    }
  }
}


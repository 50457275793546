@import '../../variables.scss';

.learn-about-section {
  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h2 {
    width: 65%;
    margin: 0;
    margin-top: 60px;
    font-size: 2rem;
    text-align: center;
    color: $text-color-dark;
    align-self: center;
  }

  p {
    width: 65%;
    margin: 0;
    margin-top: 20px;
    font-size: 1.5rem;
    text-align: center;
    align-self: center;
    color: $text-color-gray;
  }

  .learn-about-section__content {
    width: 85%;
    margin-top: 80px;
    display: flex;
    height: auto;
    justify-content: space-between;
    margin-bottom: 120px;

    .left-content {
      width: 50%;
      height: 100%;
      overflow-y: hidden;

      .left-content-proposta {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        overflow-y: hidden;

        h3,
        p {
          text-align: left;
          margin: 0;
          width: 100%;
        }

        h3 {
          font-size: 2rem;
          color: $text-color-dark;
          margin-bottom: 15px;
        }
      }

      .images-content {
        width: 100%;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;

        .notebookRelatorios {
          width: 90%;
        }

        .setas {
          width: 10%;
        }
      }
    }

    .right-content {
      width: 40%;
      height: 100%;
      overflow-y: hidden;

      .right-content-items {
        border: 1px solid black;
        border-radius: 20px;
        padding: 20px 30px;
        overflow-y: hidden;

        .items {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding-bottom: 60px;
          margin-top: 40px;
          border-bottom: 1px solid $line-color-items;

          h3,
          p {
            text-align: left;
            margin: 0;
            width: 100%;
          }

          h3 {
            font-size: 2rem;
            color: $text-color-dark;
          }
        }

        .item-first {
          margin-top: 0;
        }

        .item-last {
          border-bottom: none;
        }

        .item__image__title {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 20px;
          margin-bottom: 20px;

          img {
            width: 20%;
          }
        }
      }

      button {
        background-color: $button-color;
        color: $text-color-light;
        border: none;
        margin-top: 20px;
        width: 100%;
        padding: 10px 0px;
        border-radius: 10px;
        cursor: pointer;
        font-size: 1.2rem;
        transition: background-color 0.3s;

        &:hover {
          background-color: darken($button-color, 10%);
        }

        font-family: 'Quicksand', sans-serif;
        overflow-y: hidden;
      }
    }
  }

  .video-plataforma {
    width: 90%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .video-plataforma__content {
      width: 45%;

      h3 {
        color: $text-color-dark;
        font-size: 2rem;
        margin: 0;
        margin-bottom: 20px;
        text-align: center;
      }

      iframe {
        width: 100%;
        border-radius: 20px;
        margin-bottom: 40px;
      }
    }

    .mockupTablet {
      width: 52%;
      object-fit: contain;
    }
  }

  @media (max-width: 1024px) {
    h2 {
      width: 90%;
    }
    .learn-about-section__content {
      flex-direction: column;
      align-items: center;

      .left-content, .right-content {
        width: 100%;
      }

      .left-content {
        .images-content {
          flex-direction: row;
          justify-content: space-around;

          .setas {
            display: none;
          }

          .notebookRelatorios {
            width: 45%;
          }
        }
      }

      .right-content {
        margin-top: 40px;
      }
    }

    .video-plataforma {
      flex-direction: column;
      align-items: center;

      .video-plataforma__content, .mockupTablet {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 1.5rem;
    }

    p {
      width: 90%;
      font-size: 1.2rem;
    }

    .learn-about-section__content {
      margin-bottom: 80px;
      .right-content {
        .right-content-items {
          padding: 10px 20px;

          .items {
            padding-bottom: 30px;
            margin-top: 20px;

            h3 {
              font-size: 1.5rem;
            }

            p {
              font-size: 1rem;
            }

            .item__image__title img {
              width: 30%;
            }
          }
        }
        
        button {
          font-size: 1rem;
        }
      }
      .left-content {
        .left-content-proposta {
          h3 {
            font-size: 1.5rem;
            text-align: center;
          }
        }
      }
    }

    .video-plataforma .video-plataforma__content h3 {
      font-size: 1.5rem;
    }

    .video-plataforma .mockupTablet {
      margin: 0;
    }

    .video-plataforma iframe {
      height: 30vh;
    }
  }

  @media (max-width: 480px) {
    h2 {
      font-size: 1.2rem;
    }

    p {
      font-size: 1rem;
    }

    .learn-about-section__content {
      .right-content {
        .right-content-items {
          .items {
            h3 {
              font-size: 1.2rem;
            }

            p {
              font-size: 0.9rem;
            }
          }
        }
      }
    }

    .video-plataforma .video-plataforma__content h3 {
      font-size: 1.2rem;
    }
  }

  @media (min-width: 1650px) {
    h2 {
      font-size: 2.5rem;
      margin-top: 80px;
    }

    p {
      font-size: 2rem;
    }

    .learn-about-section__content {
      width: 80%;
      .left-content {
        width: 50%;
        .left-content-proposta {
          h3 {
            font-size: 2.5rem;
          }
        }
      }
      .right-content {
        width: 40%;
        .right-content-items {
          padding: 30px 40px;
          .items {
            h3 {
              font-size: 2.5rem;
            }

            p {
              font-size: 2rem;
            }

            .item__image__title img {
              width: 15%;
            }
          }
        }

        button {
          font-size: 1.5rem;
        }
      }
    }

    .video-plataforma {
      .video-plataforma__content {
        h3 {
          font-size: 2.5rem;
        }

        iframe {
          border-radius: 30px;
        }
      }

      .mockupTablet {
        width: 50%;
      }
    }
  }
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
}
